import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Row,
  Col,
  FormFeedback,
  FormText
} from 'reactstrap';
import * as MaterialIcons from '@material-ui/icons';
import { HexColorPicker } from 'react-colorful';
import validator from 'core/validator/customValidator';
import TextCounter from 'components/TextCounter';
import { BaseInput, BaseLabelCounter } from 'components';

function CreateEditBookmarks(props) {
  const { t } = useTranslation();
  const { bookmark, isPopupOpen, toggleClosePopup, handleSaveBookmark, mode, onChangeValue } = props;

  const materialIcons = [
    'Home',
    'Star',
    'Bookmark',
    'Favorite',
    'Person',
    'Settings',
    'Info',
    'Help',
    'Search',
    'Notifications'
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [errDisplayTitle, setErrDisplayTitle] = useState('');
  const [errUrl, setErrUrl] = useState('');
  const [errIcon, setErrIcon] = useState('');

  const lang = localStorage.getItem('sysLanguage') || 'en_US';

  const isCheckDisplayTitle = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    }
    setErrDisplayTitle(err);
    return !err;
  };

  const isCheckValidURL = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    } else if (
      (value.trim().length > 0 && !validator.isURL(value, { require_protocol: true }) && !value.endsWith(' ')) ||
      value.endsWith('. ') ||
      !value.split('').includes('.')
    ) {
      err = 'bookmark.urlInValid';
    }
    setErrUrl(err);
    return !err;
  };

  const isCheckValidIcon = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequiredSelect';
    }
    setErrIcon(err);
    return !err;
  };

  const validateAll = () => {
    const isValidDisplayTitle = isCheckDisplayTitle(bookmark.title);
    const isValidURL = isCheckValidURL(bookmark.bookmarkUrl);
    const isValidIcon = isCheckValidIcon(bookmark.icon);
    return isValidDisplayTitle && isValidURL && isValidIcon;
  };

  const filteredIcons = materialIcons.filter(iconName => iconName.toLowerCase().includes(searchTerm.toLowerCase()));

  const DEFAULT_COLOR = '#ffffff';

  const inputChange = (type, value) => {
    let isValidDisplayTitle = true;
    let isValidURL = true;
    let isValidIcon = true;
    if (type === 'title') isValidDisplayTitle = isCheckDisplayTitle(value);
    if (type === 'bookmarkUrl') isValidURL = isCheckValidURL(value);
    if (type === 'icon') {
      isValidIcon = isCheckValidIcon(value);
      setSearchTerm('');
    }

    onChangeValue(type, value);
  };

  const onSubmit = () => {
    if (validateAll()) handleSaveBookmark(bookmark);
    setSearchTerm('');
  };

  const cancelDialog = e => {
    if (!e.detail || e.detail === 1) {
      e.preventDefault();
      setErrDisplayTitle('');
      setErrUrl('');
      setErrIcon('');
      toggleClosePopup();
      setSearchTerm('');
    }
  };

  return (
    <>
      <Modal isOpen={isPopupOpen} toggle={() => toggleClosePopup(false)} zIndex={2050}>
        <ModalHeader>
          {lang === 'en_US' && (
            <div className="row">
              <div style={{ paddingRight: mode === 'Add' ? '240px' : '260px' }}>
                {mode === 'Add' ? <b>{t('bookmark.create')}</b> : <b>{t('bookmark.edit')}</b>}
              </div>
              <button type="button" className="btn btn-secondary" aria-label="Close" onClick={cancelDialog}>
                <i className="material-icons">close</i>
              </button>
            </div>
          )}
          {lang === 'ja_JP' && (
            <div className="row">
              <div style={{ paddingRight: mode === 'Add' ? '162px' : '206px' }}>
                {mode === 'Add' ? <b>{t('bookmark.create')}</b> : <b>{t('bookmark.edit')}</b>}
              </div>
              <button type="button" className="btn btn-secondary" aria-label="Close" onClick={cancelDialog}>
                <i className="material-icons">close</i>
              </button>
            </div>
          )}
          {lang === 'zh_TW' && (
            <div className="row">
              <div style={{ paddingRight: mode === 'Add' ? '317px' : '317px' }}>
                {mode === 'Add' ? <b>{t('bookmark.create')}</b> : <b>{t('bookmark.edit')}</b>}
              </div>
              <button type="button" className="btn btn-secondary" aria-label="Close" onClick={cancelDialog}>
                <i className="material-icons">close</i>
              </button>
            </div>
          )}
        </ModalHeader>

        <ModalBody>
          {/** Input displayTile */}
          <FormGroup>
            <div>
              <div className="row">
                <BaseLabelCounter label={t('bookmark.title')} />
                <div style={{ paddingLeft: '5px' }}>
                  <TextCounter data={bookmark?.title} max={60} wrapclass="m-l-10" />
                </div>
              </div>

              <BaseInput
                maxLength="60"
                type="text"
                value={bookmark.title}
                onChange={value => inputChange('title', value)}
                placeholder={t('placeholder.input.Enter', { field: t('bookmark.title') })}
              />
            </div>
          </FormGroup>
          <FormFeedback className="d-block">
            {errDisplayTitle ? t(errDisplayTitle, { label: t('bookmark.title') }) : ''}
          </FormFeedback>

          {/** Input URL */}
          <FormGroup>
            <div>
              <div className="row">
                <BaseLabelCounter label={t('bookmark.url')} />
                <div style={{ paddingLeft: '5px' }}>
                  <TextCounter data={bookmark?.bookmarkUrl} max={200} wrapclass="m-l-10" />
                </div>
              </div>

              <BaseInput
                maxLength="200"
                type="bookmarkUrl"
                value={bookmark.bookmarkUrl}
                onChange={value => inputChange('bookmarkUrl', value)}
                placeholder={t('placeholder.input.Enter', { field: t('bookmark.url') })}
              />
            </div>
          </FormGroup>
          <FormText>{t('bookmark.url.example')}</FormText>
          <FormFeedback className="d-block">{errUrl ? t(errUrl, { label: t('bookmark.url') }) : ''}</FormFeedback>

          {/** Select icon */}
          <FormGroup>
            <div className="row">
              <BaseLabelCounter label={t('bookmark.icon')} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              {bookmark.icon && (
                <>
                  {React.createElement(MaterialIcons[bookmark.icon], { size: 24 })}
                  <span style={{ marginLeft: '10px' }}>
                    <b style={{ paddingTop: '1px' }}>{bookmark.icon}</b>
                  </span>
                  <Button
                    close
                    aria-label="Close"
                    onClick={e => inputChange('icon', '')}
                    style={{ marginLeft: '10px', position: 'static', marginBottom: '5px' }}
                  />
                </>
              )}
            </div>
            <FormFeedback className="d-block">{errIcon ? t(errIcon, { label: t('bookmark.icon') }) : ''}</FormFeedback>
            <InputGroup>
              <Input
                placeholder={t('placeholder.search.icon')}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              {/* <InputGroupAddon addonType="append">
                <Button onClick={() => setSearchTerm('')}>Clear</Button>
              </InputGroupAddon> */}
            </InputGroup>
            <div
              style={{
                maxHeight: '200px',
                overflowY: 'scroll',
                border: '1px solid #ddd',
                marginTop: '10px',
                padding: '10px'
              }}
            >
              {filteredIcons.length === 0 ? (
                <div style={{ textAlign: 'center', padding: '20px', color: '#777' }}>{t('bookmark.iconNotFound')}</div>
              ) : (
                <Row>
                  {filteredIcons.map((iconName, index) => {
                    const IconComponent = MaterialIcons[iconName];
                    const isSelected = bookmark.icon === iconName;
                    const buttonStyle = isSelected
                      ? {
                          transition: 'background-color 0.5s ease',
                          backgroundImage: 'linear-gradient(to bottom left, #f66, #feb47b)',
                          borderRadius: '5px',
                          padding: '0px'
                        }
                      : {};
                    return (
                      <Col xs="4" key={index} className="text-center mb-2" style={buttonStyle}>
                        <Button color="link" onClick={e => inputChange('icon', iconName)}>
                          <IconComponent size={24} />
                          <div>{iconName}</div>
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </div>
          </FormGroup>

          {/** Select color */}
          <FormGroup>
            <Label for="color">{t('bookmark.color')}</Label>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ flex: '1' }}>
                <HexColorPicker
                  color={bookmark.color ? bookmark.color : DEFAULT_COLOR}
                  onChange={e => inputChange('color', e)}
                  style={{ width: '100%', height: '100px' }}
                />
              </div>
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: bookmark.color,
                  marginLeft: '10px',
                  borderRadius: '7px',
                  border: '1px solid #000'
                }}
              />
            </div>
            {bookmark.color === '#ffffff' ? (
              <p style={{ justifyContent: 'right', display: 'flex', paddingRight: '33px' }}>
                <b>{bookmark.color}</b>
              </p>
            ) : (
              <p style={{ justifyContent: 'right', display: 'flex', paddingRight: '21px' }}>
                <b>{bookmark.color}</b>
              </p>
            )}
          </FormGroup>
        </ModalBody>

        {/** Footer */}
        <ModalFooter>
          <Button className="btn btn-secondary" onClick={cancelDialog}>
            {t('common.button.back')}
          </Button>{' '}
          <Button
            className="btn btn-primary"
            type="submit"
            onClick={onSubmit}
            disabled={
              (bookmark.bookmarkUrl.trim().length > 0 &&
                !validator.isURL(bookmark.bookmarkUrl, { require_protocol: true }) &&
                !bookmark.bookmarkUrl.endsWith(' ')) ||
              bookmark.bookmarkUrl.endsWith('. ') ||
              !bookmark.bookmarkUrl.split('').includes('.') ||
              errIcon ||
              errDisplayTitle ||
              errUrl
            }
          >
            {t('common.button.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CreateEditBookmarks;
